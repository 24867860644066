import Moment from 'react-moment';
import 'moment-timezone';

export type FormattedDateProps = {
  date: Date;
  // The string to display when the date is null
  nullString?: string;
};

const FormattedDate = (props: FormattedDateProps) => {
  return props.date ? 
    <Moment date={props.date} format="yyyy-MM-DD HH:mm:ss.SSS UTC" tz="UTC"></Moment> : 
    (props.nullString ? (<>props.nullString</>) : null);
};

export default FormattedDate;
