import "../shared/Modals.css";
import { SSEProvider } from "react-hooks-sse";
import { baseUrl } from "../../api/constants";
import { getAccessToken } from "../../api/token";
import EventSource from "eventsource";
import Log from "./Log";

export type LogContainerProps = {
  timelineName: string;
};

const LogContainer = (props: LogContainerProps) => {
  var eventSource = () =>
    new EventSource(baseUrl + `timelines/${props.timelineName}/sse?returnLastEvent=true`, {
      headers: { "X-Auth": getAccessToken() },
    });

  return (
    <SSEProvider source={eventSource}>
      <Log timelineName={props.timelineName} />
    </SSEProvider>
  );
};

export default LogContainer;
