import { faClipboard, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { TooltipOnHover } from "../shared/TooltipOnHover";

export interface AccessTokenFieldProps {
    accessToken: string;
}

const AccessTokenField = (props: AccessTokenFieldProps) => {
  const [showAccessToken, setShowAccessToken] = useState<boolean>(false);

  const toClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Token copied to clipboard")
}


  return (
    <InputGroup>
      <Form.Control
        size="sm"
        type={showAccessToken ? "text" : "password"}
        value={props.accessToken}
        readOnly={true}
      />
      <InputGroup.Text>
        {showAccessToken ? (
          <TooltipOnHover text={"Hide Access Tokens"}>
            <FontAwesomeIcon
              className="table-icon"
              icon={faEyeSlash}
              onClick={() => setShowAccessToken(false)}
            />
          </TooltipOnHover>
        ) : (
          <TooltipOnHover text={"Show Access Tokens"}>
            <FontAwesomeIcon
              className="table-icon"
              icon={faEye}
              onClick={() => setShowAccessToken(true)}
            />
          </TooltipOnHover>
        )}
        <TooltipOnHover text={"Copy Access Token to Clipboard"}>
          <FontAwesomeIcon
            className="table-icon"
            icon={faClipboard}
            onClick={() => toClipboard(props.accessToken)}
          />
        </TooltipOnHover>
      </InputGroup.Text>
    </InputGroup>
  );
};


export default AccessTokenField;