import { useEffect, useState } from "react";
import {
  Subscription,
  getAllSubscriptions,
  getSubscriptionsByTimeline,
} from "../api/subscriptions";

export const useTimelineSubscriptions = (timeline: string | undefined) => {
 
  let [subscriptions, setSubscriptions] = useState<Subscription[] | undefined>(
    undefined
  );

  const fetchSubscriptions = async (timelineName: string | undefined) => {
    if (!timelineName) {
      let newSubscriptions = await getAllSubscriptions();
      setSubscriptions(newSubscriptions);  
    } else {
      let newSubscriptions = await getSubscriptionsByTimeline(timelineName);
      setSubscriptions(newSubscriptions);
    }
  };

  useEffect(() => {
    fetchSubscriptions(timeline);
  }, [timeline]);
  return {subscriptions, fetchSubscriptions};
};
