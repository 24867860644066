import { create, list, remove, validate, ValidationResponse } from './resource';

export enum SubscriptionType {
    HTTP_FETCH,
    HTTP_PUSH
}

export type Subscription = {
    name: string,
    timelineName: string,
    startTime: Date,
    creatorId: string,
    creationTime: Date,
    type: string,
    properties: any,
}

export interface GetSubscriptionsResponseBody {
    subscriptions: Subscription[]
}

export const getAllSubscriptions = async () => {
    const body: GetSubscriptionsResponseBody = await list('subscriptions');
    return body.subscriptions;
}

export const getSubscriptionsByTimeline = async (timelineName: string) => {
    const body: GetSubscriptionsResponseBody = await list('timelines/' + timelineName + '/subscriptions');
    return body.subscriptions;
}

export const createSubscription = async (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>, startTime: Date | undefined) => {
    const propertiesObject = Object.fromEntries(properties);
    await create('subscriptions', { name: subscriptionName, timelineName: timelineName, type: SubscriptionType[type as any], properties: propertiesObject, startTime: startTime });
}

export const validateSubscription = async (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>): Promise<ValidationResponse> => {
    try {
        const propertiesObject = Object.fromEntries(properties);
        return validate('subscriptions', { name: subscriptionName, timelineName: timelineName, type: SubscriptionType[type as any], properties: propertiesObject})
    } catch {
        return Promise.resolve({
            valid: true,
            errorMessage: undefined,
            fieldErrorMessages: undefined
        })
    }
}

export const deleteSubscription = async (subscriptionName: string) => {
    return remove('subscriptions', subscriptionName);
}