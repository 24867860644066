import { useEffect, useState } from "react";

export type HeadingItem = {
    id: string,
    title: string,
    type: string,
}

export const useHeadingsData = () => {
    const [nestedHeadings, setNestedHeadings] = useState<HeadingItem[]>([]);

    useEffect(() => {
        const headingElements = Array.from(
            document.querySelectorAll<HTMLElement>("h1, h2, h3")
        );
        const headingItems = headingElements.map(({ id, innerText, nodeName }) => {
            return { id: id, title: innerText, type: nodeName }
        });
        setNestedHeadings(headingItems);
    }, []);

    return { nestedHeadings };
};
