export type ExampleRequests = {
    codeExamples: any
}

export const getExampleRequests = async (baseUrl: string) => {
    return fetchExampleRequests(baseUrl);
}

export const fetchExampleRequests = async (baseUrl: string): Promise<ExampleRequests> => {
    var url = new URL(baseUrl + 'examples');
    const params = {
        baseUrl: baseUrl
    };

    url.search = new URLSearchParams(params).toString();
    return fetch(url.toString(), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }
        throw Error("Get example requests request failed (status: " + res.status + ")");
    }).catch(err => {
        throw Error("Get example requests request failed");
    });
}
