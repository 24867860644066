import {OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap";
import React from "react";

export interface TooltipOnHoverProps {
	children: React.ReactElement;
	text: string;
}

export const TooltipOnHover = (props: TooltipOnHoverProps) => {
	const renderTooltip = (props: TooltipProps, text: string) => (
		<Tooltip id="button-tooltip" {...props}>{text}</Tooltip>
	);


	return (
		<OverlayTrigger
			placement="right"
			delay={{show: 250, hide: 400}}
			overlay={p => renderTooltip(p, props.text)}
		>
			{props.children}
		</OverlayTrigger>
	);
}