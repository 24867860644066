import {
  faEye,
  faMinusCircle,
  faPlusCircle,
  faShare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createTimeline,
  deleteTimeline,
  validateTimeline
} from "../../api/timelines";
import useTimelines from "../../hooks/useTimelines";
import LoadingIndicator from "../shared/LoadingIndicator";
import ModalTextInput from "../shared/ModalTextInput";
import ShareTimelineInput from "./ShareTimelineInput";
import "../shared/Tables.css";
import { TooltipOnHover } from "../shared/TooltipOnHover";
import FormattedDate from "../shared/FormattedDate";

const TimelinesList = () => {
  let navigate = useNavigate();

  const {timelines, fetchTimelines} = useTimelines();

  const [showTimelineNameInput, setShowTimelineNameInput] = useState(false);

  const [selectedTimeline, setSelectedTimeline] = useState("");
  const [showDeleteTimelineInput, setShowDeleteTimelineInput] = useState(false);
  const [showShareTimelineInput, setShowShareTimelineInput] = useState(false);

  const addTimeline = async (timelineName: string) => {
    await createTimeline(timelineName);
    toast.success(`Timeline '${timelineName}' created`);
    await fetchTimelines();
  };

  const removeTimeline = async (timelineName: string) => {
    await deleteTimeline(timelineName);
    toast.success(`Timeline '${timelineName}' deleted`);
    await fetchTimelines();
  };

  const validateDeleteName = (timelineName: string, input: string) => {
    if (selectedTimeline !== input) {
      return "The given name does not match.";
    }
  };

  const askUserForAdditionalShareInformation = (timelineName: string) => {
    setSelectedTimeline(timelineName);
    setShowShareTimelineInput(true);
  };

  const viewTimelineDetails = (timelineName: string) => {
    navigate(`/timelines/${timelineName}`);
  };

  const askUserToConfirmDelete = (timelineName: string) => {
    setSelectedTimeline(timelineName);
    setShowDeleteTimelineInput(true);
  };

  const validateTimelineInput = async (
    timelineName: string
  ): Promise<string | undefined> => {
    const { valid, errorMessage } = await validateTimeline(timelineName);
    return valid ? undefined : errorMessage;
  };

  if (!timelines) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <ModalTextInput
        title="Create new Timeline"
        message="Please provide a name for the new Timeline:"
        show={showTimelineNameInput}
        setShow={setShowTimelineNameInput}
        onConfirm={addTimeline}
        validateInput={validateTimelineInput}
      />
      <ModalTextInput
        title="Delete Timeline"
        message={
          "To confirm the deletion of Timeline '" +
          selectedTimeline +
          "' please confirm its name."
        }
        show={showDeleteTimelineInput}
        setShow={setShowDeleteTimelineInput}
        validateInput={(input: string) =>
          validateDeleteName(selectedTimeline, input)
        }
        onConfirm={(name: string) => removeTimeline(name)}
      />
      <ShareTimelineInput
        timelineName={selectedTimeline}
        show={showShareTimelineInput}
        setShow={setShowShareTimelineInput}
      />

      <div className="tableHeader">
        <h1>Your Timelines</h1>
        <p>Here, you can manage and inspect all your Timelines.</p>
      </div>
      <div className="tableAction">
        <TooltipOnHover text={"Create new Timeline"}>
          <Button
            variant="add-item"
            onClick={() => setShowTimelineNameInput(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </TooltipOnHover>
      </div>
      {timelines.length === 0 ? (
        <div>No Timelines found. Feel free to add some.</div>
      ) : (
        <Table className="listingTable" hover size="sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Creation Time</th>
              <th>Created by</th>
              <th>Subscriptions</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {timelines.map((timeline, idx) => (
              <tr key={idx} onClick={() => viewTimelineDetails(timeline.name)}>
                <td className="text">{timeline.name}</td>
                <td className="text"><FormattedDate  date={timeline.creationTime} /></td>
                <td className="text">{timeline.creatorId}</td>
                <td>{timeline.subscriptionCount}</td>
                <td className="rowButtons">
                  <TooltipOnHover text={"Share Timeline"}>
                    <Button
                      variant="action"
                      onClick={(e) => {
                        e.stopPropagation();
                        askUserForAdditionalShareInformation(timeline.name);
                      }}
                    >
                      <FontAwesomeIcon icon={faShare} />
                    </Button>
                  </TooltipOnHover>
                  <TooltipOnHover text={"Show details"}>
                    <Button
                      variant="action"
                      onClick={(e) => {
                        e.stopPropagation();
                        viewTimelineDetails(timeline.name);
                      }}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                  </TooltipOnHover>
                  <TooltipOnHover text={"Delete Timeline"}>
                    <Button
                      variant="action"
                      onClick={(e) => {
                        e.stopPropagation();
                        askUserToConfirmDelete(timeline.name);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Button>
                  </TooltipOnHover>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default TimelinesList;
