import { getAccessToken } from "./token";
import { baseUrl } from "./constants";

export async function list<R>(resourcePath: string): Promise<R> {
  let accessToken = getAccessToken();
  if (!accessToken) {
    console.log("About to throw up...");
    throw Error("No access token provided");
  }
  try {
    const response = await fetch(baseUrl + resourcePath, {
      method: "GET",
      headers: {
        "X-Auth": accessToken,
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw Error(
        "Unexpected response status on /" +
          resourcePath +
          " : " +
          response.status
      );
    }
    return response.json();
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error("Failed get request on /" + resourcePath);
    }
    throw e;
  }
}

export async function create<B>(resourcePath: string, body: B): Promise<void> {
  let accessToken = getAccessToken();
  if (!accessToken) {
    throw Error("No access token provided");
  }
  try {
    const response = await fetch(baseUrl + resourcePath, {
      method: "POST",
      headers: {
        "X-Auth": accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status !== 200) {
      throw Error("Error in API request");
    }
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error("Failed post request on /" + resourcePath);
    }
    throw e;
  }
}

export async function createBlob(
  resourcePath: string,
  body: Blob
): Promise<void> {
  let accessToken = getAccessToken();
  if (!accessToken) {
    throw Error("No access token provided");
  }
  try {
    const response = await fetch(baseUrl + resourcePath, {
      method: "POST",
      headers: {
        "X-Auth": accessToken,
        "Content-Type": "application/json",
      },
      body: body,
    });
    if (response.status !== 200) {
      throw Error("Error in API request");
    }
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error("Failed post request on /" + resourcePath);
    }
    throw e;
  }
}

export async function current<B>(resourcePath: string): Promise<B> {
  let accessToken = getAccessToken();
  if (!accessToken) {
    throw Error("No access token provided");
  }
  try {
    const response = await fetch(baseUrl + resourcePath + "/current", {
      method: "GET",
      headers: {
        "X-Auth": accessToken,
        "Content-Type": "application/json",
      },
    });
    if (response.status !== 200) {
      throw Error("Error in API request");
    }
    return response.json();
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error("Failed get request on /" + resourcePath);
    }
    throw e;
  }
}

export interface ValidationResponse {
  valid: boolean;
  errorMessage: string | undefined;
  fieldErrorMessages: any;
}

export async function validate<B>(
  resourcePath: string,
  body: B
): Promise<ValidationResponse> {
  let accessToken = getAccessToken();
  if (!accessToken) {
    throw Error("No access token provided");
  }
  try {
    const response = await fetch(baseUrl + resourcePath + "/validate", {
      method: "POST",
      headers: {
        "X-Auth": accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (response.status !== 200) {
      throw Error("Error in API request");
    }

    return response.json();
  } catch (e) {
    if (e instanceof TypeError) {
      throw Error("Failed validate request on /" + resourcePath);
    }
    throw e;
  }
}

export async function remove(resourcePath: string, resourceId: string) {
  let accessToken = getAccessToken();
  if (!accessToken) {
    throw Error("No access token provided");
  }

  const response = await fetch(baseUrl + resourcePath + "/" + resourceId, {
    method: "DELETE",
    headers: {
      "X-Auth": accessToken,
    },
  });
  if (response.status !== 200) {
    throw Error("Error in API request");
  }
}
