import {useEffect, useState} from "react";
import {Client, getClients} from "../api/clients";

const useClients = (): [Client[] | undefined, () => Promise<void>] => {
    let [clients, setClients] = useState<Client[] | undefined>(undefined);

    const fetchClients = async () => {
        const newClients = await getClients();
        setClients(newClients);
    };

    useEffect(() => {
        setClients(undefined);
        fetchClients();
    }, []);

    return [clients, fetchClients];
}
export default useClients;
