import React, { ErrorInfo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// need to use a class component here, cause there is no
// hook for catching errors yet
class NotificationBoundary extends React.Component {
  promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    toast.error("" + event.reason);
    console.warn("Promise Rejection Error: ", event);
  };

  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
  }

  componentWillUnmount() {
    window.removeEventListener(
      "unhandledrejection",
      this.promiseRejectionHandler
    );
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    toast.error("" + error.message);
    console.warn("Error: ", error);
    console.warn("Error Info: ", errorInfo);
  }

  render() {
    return (
      <div>
        <ToastContainer position="bottom-left" />
        {this.props.children}
      </div>
    );
  }
}

export default NotificationBoundary;
