import { faMinusCircle, faPlus, faRobot, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Badge, Button, Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { createClient, deleteClient, validateClient } from '../../api/clients';
import useClients from '../../hooks/useClients';
import useCurrentClient from '../../hooks/useCurrentClient';
import AccessTokenField from "./AccessTokenField";
import CreateUserInput from './CreateUserInput';
import ModalTextInput from '../shared/ModalTextInput';
import FormattedDate from "../shared/FormattedDate";
import LoadingIndicator from "../shared/LoadingIndicator";
import '../shared/Tables.css';
import { TooltipOnHover } from "../shared/TooltipOnHover";

const ClientsList = () => {
    const [clients, refetch] = useClients();
    const [currentClient] = useCurrentClient();
    const [showCreateClientInput, setShowCreateClientInput] = useState<boolean>(false);
    const [showCreateUserInput, setShowCreateUserInput] = useState<boolean>(false);
    const [selectedClient, setSelectedClient] = useState("");
    const [showDeleteClientInput, setShowDeleteClientInput] = useState(false);
    
    const addClient = async (clientName: string) => {
        await createClient({
            clientId: clientName,
            admin: false,
            human: false,
        });
        toast.success(`Client '${clientName}' created`);
    
        refetch();
    };

    const addUser = async (clientName: string, admin: boolean) => {
        await createClient({
            clientId: clientName,
            admin,
            human: true,
        });
        toast.success(`User '${clientName}' added`);
    
        refetch();
    };

    const validateClientInput = async (clientName: string) => {
        const { valid, errorMessage } = await validateClient({
            clientId: clientName,
            human: false,
            admin: false
        });
        return valid
            ? undefined
            : errorMessage;
    }

    const validateUserInput = async (clientName: string, admin: boolean) => {
        const { valid, errorMessage } = await validateClient({
            clientId: clientName,
            human: true,
            admin
        });
        return valid
            ? undefined
            : errorMessage;
    }

    const editClient = () => {
        // FIXME Implement "Delete Client"
    };

    const removeClient = async (clientId: string) => {
        await deleteClient(clientId);
        toast.success(`Client '${clientId}' deleted`);
    
        await refetch();
    };

    const validateDeleteName = (clientId: string, input: string) => {
        if (selectedClient !== input) {
            return "The given name does not match."
        }
    }

    const askUserToConfirmDelete = (clientId: string) => {
        setSelectedClient(clientId);
        setShowDeleteClientInput(true);
    }

    return clients === undefined || currentClient === undefined ? (
        <LoadingIndicator />
    ) : (
        <Container>
            <ModalTextInput
                title="Create new Client"
                message="Please provide a name for the new Client:"
                show={showCreateClientInput}
                setShow={setShowCreateClientInput}
                onConfirm={addClient}
                validateInput={validateClientInput}
            />
            <CreateUserInput
                title="Create new User"
                message="Please provide a name for the new User:"
                show={showCreateUserInput}
                setShow={setShowCreateUserInput}
                onConfirm={addUser}
                validateInput={validateUserInput}
            />
            <ModalTextInput
                title="Delete Client"
                message={"To confirm the deletion of Client '" + selectedClient + "' please confirm its ID."}
                show={showDeleteClientInput}
                setShow={setShowDeleteClientInput}
                validateInput={(input: string) => validateDeleteName(selectedClient, input)}
                onConfirm={(name: string) => removeClient(name)}
            />
            <div className="tableHeader">
                <h1>Clients</h1>
                <p>
                    Here, you can create and manage Clients to post and subscribe to Timelines.
                </p>
            </div>
            <div className="tableAction">
                { // FIXME Why are the icons shown in a different order?
                }
                <TooltipOnHover
                    text={"Add new Client"}
                >
                    <Button variant="add-item" onClick={() => setShowCreateClientInput(true)}><FontAwesomeIcon icon={faPlus} />&nbsp;<FontAwesomeIcon icon={faRobot} /></Button>
                </TooltipOnHover>
                {currentClient.admin ? (
                    <TooltipOnHover
                        text={"Add new User"}
                    >
                        <Button variant="add-item" onClick={() => setShowCreateUserInput(true)}><FontAwesomeIcon icon={faPlus} />&nbsp;<FontAwesomeIcon icon={faUser} /></Button>
                    </TooltipOnHover>
                ) : null}
            </div>
            {clients.length === 0 ?
                <div>No Clients found. Feel free to add some.</div> :
                <Table className="listingTable" hover size="sm">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Access Token</th>
                        {/*todo Add these columns back in, when they are available from the backend*/}
                        {/*<th>Timelines</th>*/}
                        {/*<th>Last Active</th>*/}
                        <th>Creation Time</th>
                        <th>Created by</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        clients.map((client, idx) =>
                            <tr key={idx}>
                                <td className="text">
                                    {client.clientId}&nbsp;
                                    {!client.admin || !client.human ? <><Badge bg={client.human ? "primary" : "secondary"}>{client.human ? "User" : "Client"}</Badge>&nbsp;</> : null }
                                    {client.admin ? <Badge bg="danger">Admin</Badge> : undefined}
                                </td>
                                <td className="text">
                                    <AccessTokenField accessToken={client.accessToken}/>
                                </td>
                                <td className="text">
                                    <FormattedDate date={client.creationTime} />
                                </td>
                                <td className="text">{client.creatorId}</td>
                                <td className="rowButtons">
                                    {
                                        // FIXME currentClient.admin ? <Button variant="action" onClick={editClient}><FontAwesomeIcon icon={faEdit} /></Button> : null
                                    }
                                    <TooltipOnHover
                                        text={"Delete Client"}
                                    >
                                        <Button variant="action" onClick={() => askUserToConfirmDelete(client.clientId)}><FontAwesomeIcon icon={faMinusCircle} /></Button>
                                    </TooltipOnHover>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        }
        </Container>
    );
}

export default ClientsList;
