import {useEffect, useState} from "react";
import {Timeline, getTimelines} from "../api/timelines";


const useTimelines = () => {
    let [timelines, setTimelines] = useState<Timeline[] | undefined>(undefined);
    
    const fetchTimelines = async () => {
        let newTimelines = await getTimelines();
        setTimelines(newTimelines);
    };

    useEffect(() => {
        setTimelines(undefined);    
        fetchTimelines();
    }, []);

    return {timelines, fetchTimelines};
}
export default useTimelines;
