import { HeadingItem } from '../../hooks/useHeadings';

export type TableOfContentsProps = {
    headings: HeadingItem[];
}

export const TableOfContents = (props: TableOfContentsProps) => (
    <div className="toc">
        <h1 className="heading">Documentation</h1>
        <div className="items">
            {props.headings
                .filter((heading) => heading.id)
                .map((heading) => (
                    <div key={heading.id} className={heading.type}>
                        <a href={`#${heading.id}`}>{heading.title}</a>
                    </div>
                ))}
        </div>
    </div>
);

export default TableOfContents;
