import { ReactElement, useState } from 'react';
import { baseUrl } from '../../api/constants';
import { getAccessToken } from '../../api/token';
import useExampleRequests from '../../hooks/useExamples';
import { useHeadingsData } from '../../hooks/useHeadings';
import { CodeExample } from './CodeExample';
import './DocumentationScreen.css';
import TableOfContents from './TableOfContents';

const DocumentationScreen = () => {

    const [languageKey, setLanguageKey] = useState('CURL');

    const { nestedHeadings } = useHeadingsData();

    const apiUrl = (baseUrl ? baseUrl : 'http://localhost:8080/') + 'swagger-ui.html';
    const exampleRequests = useExampleRequests(baseUrl ? baseUrl : 'http://localhost:8080/');
    const codeExamples = exampleRequests ? exampleRequests.codeExamples : undefined;

    // TODO Use access token of default client instead of access token of user
    let accessToken = getAccessToken();
    if (accessToken === undefined) {
        accessToken = "your-access-token";
    }

    const createTimelineRequest = codeExamples ? codeExamples["CREATE_TIMELINE"] : undefined;
    const postEventRequest = codeExamples ? codeExamples["POST_EVENT"] : undefined;
    const createFetchSubscriptionRequest = codeExamples ? codeExamples["CREATE_FETCH_SUBSCRIPTION"] : undefined;
    const fetchEventsRequest = codeExamples ? codeExamples["FETCH_EVENTS"] : undefined;
    const confirmEventsRequest = codeExamples ? codeExamples["CONFIRM_EVENTS"] : undefined;
    const createPushSubscriptionRequest = codeExamples ? codeExamples["CREATE_PUSH_SUBSCRIPTION"] : undefined;

    const keyword = (text: string): ReactElement => {
        return (<span className="keyword">{text}</span>);
    }

    const example = (text: string): ReactElement => {
        return (<span className="doc-example">{text}</span>);
    }

    const clientKeyword = keyword("Client");
    const clientsKeyword = keyword("Clients");
    const defaultClientKeyword = keyword("Default Client");
    const eventKeyword = keyword("Event");
    const eventsKeyword = keyword("Events");
    const subscriptionKeyword = keyword("Subscription");
    const subscriptionsKeyword = keyword("Subscriptions");
    const timelineKeyword = keyword("Timeline");
    const timelinesKeyword = keyword("Timelines");

    return (
        <div className="documentation-container">
            <TableOfContents headings={nestedHeadings} />
            <div className="documentation-content">

                <h1 id="overview">Overview</h1>

                <h2 id="main">Main concepts</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Timelines is based on the following main concents:</p>
                        <ol>
                            <li>{eventsKeyword} being an arbitrary piece of data stating that something happened</li>
                            <li>{timelinesKeyword} being a persistent, append-only, ordered set of {eventsKeyword}</li>
                            <li>{clientsKeyword} being entities that can post {eventsKeyword} to {timelinesKeyword} or read {eventsKeyword} from a {timelineKeyword} (either in an active or passive fashion)</li>
                            <li>{subscriptionsKeyword} specifying who is interested in which {timelineKeyword} and how they get notified about new {eventsKeyword} being posted to a {timelineKeyword}</li>
                        </ol>

                        <p>To give an example, consider the following basic scenario:</p>
                        <ol>
                            <li>A customer orders an item in an online shop and this creates an {example("OrderPlacedEvent")}</li>
                            <li>The {timelineKeyword} with the name {example("orders.placed")} can hold all {example("OrderPlacedEvents")}</li>
                            <li>The webshop service is a {clientKeyword} that (actively) posts {example("OrderPlacedEvents")} to the {example("orders.placed")} {timelineKeyword}</li>
                            <li>The shipping service is a {clientKeyword} that (passively) receives new {example("OrderPlacedEvents")} by having a {subscriptionKeyword} on the {example("orders.placed")} {timelineKeyword}</li>
                        </ol>
                    </div>
                    <div className="doc-example">
                    </div>
                </div>

                <h1 id="getting-started">Getting Started</h1>

                <h2 id="create-client">Creating a client and obtaining an access token</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>To create a {clientKeyword}, a user account is required. Such an account can be easily obtained by entering your email address <a href="/">here</a>.
                            This will send a magic link to your inbox which can be used to login to Timelines.
                            Once you've logged in, you can inspect your {clientsKeyword} and find a {defaultClientKeyword} that
                            is automatically created for every user account <a href="/clients">here</a> as
                            well as the respective access token. The access token is required for all
                            further communication with the Timelines API.
                        </p>
                    </div>
                    <div className="doc-example">
                    </div>
                </div>

                <h2 id="create-timeline">Creating a Timeline</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>To create a new {timelineKeyword}, a simple HTTP POST request is sufficient</p>
                        <p>To identify your new {timelineKeyword} a globally, unique name is required.</p>
                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={createTimelineRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <h2 id="post-event">Posting an Event to a Timeline</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Publishing {eventsKeyword} to a {timelineKeyword} is also as simple as it gets. Another basic HTTP POST request does the trick:</p>



                        <p>In order to publish {eventsKeyword} to a {timelineKeyword}, you need to have {keyword("WRITE")} access to the {timelineKeyword}.
                            Assuming you've created the {timelineKeyword} yourself, this is the case by default.</p>

                        <p>The payload of the {eventKeyword} is entirely up to you. As long as it is valid JSON, Timelines will consume it.</p>

                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={postEventRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <h2 id="create-subscription">Creating a Subscription and pulling Events from a Timeline</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Once {eventsKeyword} have been published to a {timelineKeyword}, the question is how you can read (i.e., consume)
                            such {eventsKeyword}. This is done by creating a {subscriptionKeyword} on the {timelineKeyword}. The most
                            basic {subscriptionKeyword} is a {keyword("HTTP Fetch Subscription")} which is a passive {subscriptionKeyword} that receives {eventsKeyword} by polling the {timelineKeyword}.
                            To create such a {subscriptionKeyword} use the following HTTP POST request:
                        </p>
                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={createFetchSubscriptionRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <div className="doc-section">
                    <div className="doc-description">
                        <p>Once the {subscriptionKeyword} has been created, you can start polling the {timelineKeyword} for new {eventsKeyword}.
                            To receive a list of new {eventsKeyword} use the following HTTP GET request:
                        </p>

                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={fetchEventsRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <div className="doc-section">
                    <div className="doc-description">
                        <p>Once the {eventKeyword} was successfully processed, it needs to be confirmed. This is required to let Timelines
                            know that there is no need to deliver this {eventKeyword} again. Confirming {eventsKeyword} is done with the following HTTP POST request:
                        </p>
                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={confirmEventsRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <h2 id="receive-http-push">Receiving HTTP Push Notifications for {eventsKeyword} on a {timelineKeyword}</h2>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Even easier than fetching {eventsKeyword}, is registering a {keyword("HTTP Push Subscription")}. Such a {subscriptionKeyword} automatically forwards new {eventsKeyword} to a URL of your choice.
                            Creating such a {subscriptionKeyword} can be done as follows:
                        </p>
                    </div>
                    <div className="doc-example">
                        <CodeExample
                            languageToCodeMap={createPushSubscriptionRequest}
                            accessToken={accessToken}
                            languageKey={languageKey}
                            setLanguageKey={setLanguageKey}
                        />
                    </div>
                </div>

                <h1 id="open-api">OpenAPI Specification</h1>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>The complete OpenAPI specification for the Timelines API can be found at <a href={apiUrl}>{apiUrl}</a>.</p>
                    </div>
                </div>

                <h1 id="patterns">Patterns</h1>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>In this section, a couple of useful patterns that are frequently required in Event-Driven Architectures are described.
                        </p>
                    </div>
                </div>

                <h2 id="idempotency-keys">Idempotency Keys</h2>
                <h3 id="idempotency-keys-when">When to use</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>You should use Idempotency Keys, if you need to make sure that Events are processed at most once.</p>
                    </div>
                </div>
                <h3 id="idempotency-keys-how">How to implement</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>To implement Idempotency Keys, your publishing service/function must add a unique identifier to every published Event.
                            This identifier must be created such that the same real world Event is identified by the exact same identifier.
                        </p>
                        <p>
                            The consuming service/function must read the Idempotency Key from the Event's payload and either check whether the Event has already been processed (and not process it again)
                            or implement processing logic that yields the exact same result no matter how often the same Event is processed.
                        </p>
                    </div>
                </div>
                <h3 id="idempotency-keys-notes">Notes</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Idempotency Keys are often natural keys from your application domain.
                            Examples include Order IDs, social security numbers, and licences plate numbers.
                            Also, Idempotency Keys can be composed of multiple parts, such as a timestamp and an ID.
                        </p>
                    </div>
                </div>

                <h2 id="transactional-outbox">Transactional Outbox</h2>
                <h3 id="transactional-outbox-when">When to use</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>You should use a Transactional Outbox, if you need to make sure that the Events you want to publish are published at least once.</p>
                    </div>
                </div>
                <h3 id="transactional-outbox-how">How to implement</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>In order to implement a Transactional Outbox, you'll need access to persistent state in your publishing service/function (e.g., provided by a relational database).
                            Instead of publishing Events right away, you'll need to store them in this persistent store (e.g., insert a new row in an 'outbox' table).
                            Storing this entry in your outbox must be done in the same transaction as the business logic related to the Event.
                        </p>
                        <p>Then, the outbox entries need to be published as Events and tagged as 'published' by a separate thread/process.
                            To do so, the Events should be published within the transaction that tags them as 'published'. If publications fails,
                            the transaction must be canceled.
                        </p>
                    </div>
                </div>
                <h3 id="transactional-outbox-notes">Notes</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>Note that the Transactional Outbox does not prevent publication of duplicate Events.
                            There is always the possibility that your 'publication transaction' fails after publishing
                            the Event leaving the entry in the Outbox untagged.
                        </p>
                    </div>
                </div>

                <h2 id="state-change-events">State Change Events</h2>
                <h3 id="state-change-events-when">When to use</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>You should can State Change Events, if you need to let other services/functions know that your (persistent) data has changed.</p>
                    </div>
                </div>
                <h3 id="state-change-events-how">How to implement</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>In order to implement State Change Events, you'll need emit an Event whenever your (persistent) data changes.
                            For example, if a new domain entity is created, you'll need to emit a 'Created' Event.
                            If an existing domain entity is modified, you'll need to emit a 'Modified' Event.
                            The same holds true for deleting domain entities.
                        </p>
                        <p>A service/function that is interested in your data can recreate the state of your data using Event Sourcing.
                            To do so it reads the published Change Events and applies these Events consecutively to an initially empty data set.
                        </p>
                    </div>
                </div>
                <h3 id="state-change-events-notes">Notes</h3>
                <div className="doc-section">
                    <div className="doc-description">
                        <p>In order to allow the consuming service/function to recreate the state of your data - even if the Events are published in a different 
                            order - you'll need to emit complete Change Events (i.e., every Event must contain the full data of the affected domain entity).
                            The publishing service/function also needs to add a timestamp to every Change Event to allow the consuming service/function to ignore 
                            events that arrive after an Event (that refers to the same domain entity), which happened later, but was already processed.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DocumentationScreen;
