import '../shared/Modals.css';
import { useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import LoadingIndicator from "../shared/LoadingIndicator";
import { getTimelineAccessRights, grantAccess, TimelineAccessRight } from "../../api/timelines";
import { Client } from "../../api/clients";
import useClients from "../../hooks/useClients";
import useCurrentClient from '../../hooks/useCurrentClient';
import { toast } from 'react-toastify';

export type ShareTimelineInputProps = {
	timelineName: string;
	show: boolean;
	setShow: (show: boolean) => void;
	onClose?: () => void;
}

const ShareTimelineInput = (props: ShareTimelineInputProps) => {

	const [clientId, setClientId] = useState("");
	const [accessRights, setAccessRights] = useState<string[]>([]);

	const [clients] = useClients();
	const [currentClient] = useCurrentClient();

	const close = () => {
		setClientId("");
		setAccessRights([]);
		props.setShow(false);
		if (props.onClose) {
			props.onClose()
		}
	};

	const share = async () => {
		const accessRightArray = accessRights.map(ar => TimelineAccessRight[ar as keyof typeof TimelineAccessRight]);
		const validClients = getValidClients();
		if (validClients === undefined) {
			toast.error("Invalid client");
			console.log("Invalid client");
			return;
		}

		const effectiveClientId = clientId ? clientId : validClients[0].clientId;
		await grantAccess(props.timelineName, effectiveClientId, accessRightArray);
		setClientId("");
		setAccessRights([]);
		toast.success("Timeline shared")
		props.setShow(false);
		if (props.onClose) {
			props.onClose()
		}
	}

	const toFirstUpper = (text: string) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	const getValidClients = () => {
		let validClients: Client[] | undefined = undefined;
		if (clients && currentClient) {
			validClients = clients.filter(next => next.clientId !== currentClient.clientId);
		}
		return validClients;
	}

	const updateClientId = async (clientId : string) => {
		setClientId(clientId);
		const timelineAccessRights = await getTimelineAccessRights(props.timelineName);
		const ar = timelineAccessRights?.find(next => next.clientId === clientId)?.accessRights;
		let accessRightsForClient: TimelineAccessRight[] = ar ? ar : [];
		const newAccessRights = accessRightsForClient.map(next => next.toString());
		setAccessRights(newAccessRights);
	}

	// Valid clients are all clients except the current client (because we can't grant access to ourselves)
	let validClients: Client[] | undefined = undefined;
	if (clients && currentClient) {
		validClients = getValidClients();
	}

	if (clientId === "" && validClients && validClients.length > 0 && props.show) {
		updateClientId(validClients[0].clientId);
	}

	return (validClients === undefined || currentClient === undefined) ? (
		<LoadingIndicator />
	) : validClients.length === 0 ? (
		<Modal show={props.show} onHide={close}>
			<Modal.Header closeButton>
				<Modal.Title>No Clients to share with</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group controlId="noClient">
					<Form.Label>You'll need to create a Client before your can grant access to a Timeline.</Form.Label>
				</Form.Group>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="cancel" onClick={close}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	) : (
		<>
			<Modal show={props.show} onHide={close}>
				<Modal.Header closeButton>
					<Modal.Title>Share timeline {props.timelineName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group controlId="clientIdForm">
						<Form.Label>Select a Client to grant access to the Timeline:</Form.Label>
						<Form.Select
							className="input"
							key="clientId"
							value={clientId}
							onChange={e => {
								updateClientId(e.target.value);
							}}>
							{validClients
								.map((client, idx) => (
									<option key={idx}>{client.clientId}</option>
								))}
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="accessRightsLabel">
						<Form.Label>Access rights to grant:</Form.Label>
					</Form.Group>
					<Form.Group controlId="accessRights">
						{Object.keys(TimelineAccessRight)
							.filter(key => !isNaN(Number(TimelineAccessRight[key as any])))
							.map(key => (
								<Form.Check
									className="input"
									type={'switch'}
									inline
									id={key.toLowerCase() + "-switch"}
									key={key.toLowerCase()}
									checked={accessRights.indexOf(key) >= 0}
									label={toFirstUpper(key.toLowerCase())}
									onChange={e => {
										setAccessRights((currentState) => {
											if (currentState.indexOf(key) >= 0) {
												return currentState.filter(next => next !== key);
											} else {
												return [...currentState, key];
											}
										})
									}}
								/>
							))
						}
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="cancel" onClick={close}>
						Close
					</Button>
					<Button variant="confirm" onClick={share}>
						Share
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ShareTimelineInput;