import "../shared/Modals.css";
import { useSSE } from "react-hooks-sse";
import ReactJson from "react-json-view";
import { Table } from "react-bootstrap";
import FormattedDate from "../shared/FormattedDate";

export type LogProps = {
  timelineName: string;
};

type State = {
  events: string[];
};

const Log = (props: LogProps) => {
  const state = useSSE<State>(
    "events",
    {
      events: [],
    },
    {
      stateReducer(prevState, action) {
        const data = action.event.data;
        if (prevState.events.includes(data)) {
          return prevState;
        }

        return {
          events: [data, ...prevState.events],
        };
      },
    }
  );

  return (
    <Table className="listingTable" hover size="sm">
      <thead>
        <tr>
          <th>Event number</th>
          <th>Event date</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {state.events.map((event: any, idx: number) => {
          const json = JSON.parse(event);

          const message = json.payload
            ? Buffer.from(json.payload, "base64").toString("utf-8")
            : "no payload";
          const id = json.sequenceNumber ? json.sequenceNumber : "no event number";
          const timestamp = json.eventDate;
          var eventJson = { payload: message };
          try {
            eventJson = JSON.parse(message);
          } catch (e) {
            console.log("Error parsing payload: ", message);
          }
          return (
            <tr key={id}>
              <td className="text">{id}</td>
              <td className="text"><FormattedDate date={timestamp} nullString="no event date" /></td>
              <td className="text">
                <ReactJson
                  collapsed={1}
                  name={null}
                  displayDataTypes={false}
                  displayObjectSize={false}
                  theme="rjv-default"
                  src={eventJson}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Log;
