import {useEffect, useState} from "react";
import {Client, getCurrentClient} from "../api/clients";
import useAccessToken from "./useAccessToken";

const useCurrentClient = (): [Client | undefined, () => Promise<void>] => {
    let token = useAccessToken();
    let [currentClient, setCurrentClient] = useState<Client | undefined>(undefined);

    const fetchCurrentClient = async () => {
        try {
            const currentClient = await getCurrentClient();
            setCurrentClient(currentClient);
        } catch (e) {
            console.log("Error fetching current client: ", e);
            setCurrentClient(undefined);
        }
    };

    useEffect(() => {
        setCurrentClient(undefined);
        fetchCurrentClient();
    }, [token]);

    return [currentClient, fetchCurrentClient];
}
export default useCurrentClient;
