import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import ClientsList from './components/clients/ClientsList';
import DocumentationScreen from './components/docs/DocumentationScreen';
import Footer from './components/shared/Footer';
import MagicLinkScreen from './components/magic/MagicLinkScreen';
import MagicLinksScreen from './components/magic/MagicLinksScreen';
import MenuBar from './components/shared/MenuBar';
import NotificationBoundary from './components/shared/NotificationBoundary';
import SubscriptionsList from './components/subscriptions/SubscriptionsList';
import TimelineScreen from './components/timelines/TimelineScreen';
import TimelinesList from './components/timelines/TimelinesList';
import WelcomeScreen from './components/welcome/WelcomeScreen';
import useAccessToken from './hooks/useAccessToken';

const App = () => {

  return (
    <div className="App">
      <NotificationBoundary>
        <MenuBar />
        <div className="App-body">
          <Routes>
            <Route path="/" element={<RequireAuth><TimelinesList /></RequireAuth>} />
            <Route path="welcome" element={<WelcomeScreen />} />
            <Route path="docs" element={<DocumentationScreen />} />
            <Route path="magic" element={<MagicLinksScreen />}>
              <Route path=":token" element={<MagicLinkScreen />} />
            </Route>
            <Route path="clients" element={<RequireAuth><ClientsList /></RequireAuth>} />
            <Route path="timelines/:timelineName" element={<RequireAuth><TimelineScreen /></RequireAuth>} />
            <Route path="timelines" element={<RequireAuth><TimelinesList /></RequireAuth>} />
            
            <Route path="subscriptions" element={<RequireAuth><SubscriptionsList /></RequireAuth>} />
          </Routes>
        </div>
      </NotificationBoundary>
      <Footer />
    </div >
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const token = useAccessToken();

  if (!token) {
    // Redirect them to the /welcome page if not authenticated
    return <Navigate to="/welcome" />;
  }

  return children;
}



export default App;
