import './CodeExample.css'
import { Tabs, Tab, Button } from 'react-bootstrap';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export type CodeExampleProps = {
    languageToCodeMap: any;
    accessToken: string;
    languageKey: string;
    setLanguageKey: (key: string) => void;
}

export const CodeExample = (props: CodeExampleProps) => {

    const toClipboard = (code: string) => {
        navigator.clipboard.writeText(code);
        toast.success("Code copied to clipboard")
    }

    const prepareCode = (language: string): string => {
        const examplePojo = props.languageToCodeMap ? props.languageToCodeMap[language] : undefined;
        if (examplePojo === undefined) {
            return "";
        }

        const code = examplePojo.code;
        if (code === undefined) {
            return "";
        }

        return code.replace("YOUR_ACCESS_TOKEN", props.accessToken);
    }

    return (
        <div className="codeBlock">
            <Tabs defaultActiveKey={props.languageKey} activeKey={props.languageKey} id="code-example" onSelect={(k) => k !== null ? props.setLanguageKey(k) : null}>
                {props.languageToCodeMap ? Object.keys(props.languageToCodeMap).map(key =>
                    <Tab eventKey={key} title={props.languageToCodeMap[key].languageLabel} tabClassName="code-tab">
                        <Button variant="copy-code" onClick={() => { toClipboard(prepareCode(key)) }}><FontAwesomeIcon icon={faCopy} /></Button>
                        <SyntaxHighlighter
                            showLineNumbers
                            wrapLines
                            wrapLongLines
                            language={props.languageToCodeMap[key].highlighting}
                            style={atomOneDark}>
                            {prepareCode(key)}
                        </SyntaxHighlighter>
                    </Tab>
                ) : null}
            </Tabs>
        </div>
    );
}

export default CodeExample;
