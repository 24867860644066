import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { ValidationResponse } from "../../api/resource";
import { createSubscription, SubscriptionType, validateSubscription, deleteSubscription } from "../../api/subscriptions";
import { useTimelineSubscriptions } from "../../hooks/useSubscriptions";
import LoadingIndicator from "../shared/LoadingIndicator";
import ModalTextInput from "../shared/ModalTextInput";
import "../shared/Tables.css";
import { TooltipOnHover } from "../shared/TooltipOnHover";
import CreateSubscriptionInput from "./CreateSubscriptionInput";
import SubscriptionsTable from "./SubscriptionsTable";
export type SubscriptionsEditorProps = {
  timeline?: string;
};

const SubscriptionsEditor = (props: SubscriptionsEditorProps) => {
  const { subscriptions, fetchSubscriptions } = useTimelineSubscriptions( props.timeline);

  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [showSubscriptionCreateInput, setShowSubscriptionCreateInput] = useState(false);
  const [showDeleteSubscriptionInput, setShowDeleteSubscriptionInput] = useState(false);

  const addSubscription = async (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>, startTime: Date | undefined) => {
    await createSubscription(
      timelineName,
      subscriptionName,
      type,
      properties,
      startTime
    );
    toast.success(`Subscription '${subscriptionName}' created`);
    await fetchSubscriptions(props.timeline);
  };

  const editSubscription = () => {
    // FIXME Implement "Edit Subscription"
  };

  const removeSubscription = async (subscriptionName: string) => {
    await deleteSubscription(subscriptionName);
    toast.success(`Subscription '${subscriptionName}' deleted`);
    await fetchSubscriptions(props.timeline);
  };

  const validateDeleteName = (subscriptionName: string, input: string) => {
    if (selectedSubscription !== input) {
      return "The given name does not match.";
    }
  };

  const askToDeleteSubscription = (subscriptionName: string) => {
    setSelectedSubscription(subscriptionName);
    setShowDeleteSubscriptionInput(true);
  };

  const validateSubscriptionInput = async (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>): Promise<ValidationResponse> => {
    try {
      const validationResponse = await validateSubscription(timelineName, subscriptionName, type, properties);
      return validationResponse;
    } catch (e) {
      // FIXME for now return undefined (valid), when the validation function throws an error
      return {valid: true, errorMessage: undefined, fieldErrorMessages: undefined};
    }
  };

  if (subscriptions === undefined) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <CreateSubscriptionInput
        timeline={props.timeline}
        show={showSubscriptionCreateInput}
        setShow={setShowSubscriptionCreateInput}
        onConfirm={addSubscription}
        validateInput={validateSubscriptionInput}
      />
      <ModalTextInput
        title="Delete Subscription"
        message={
          "To confirm the deletion of Subscription '" +
          selectedSubscription +
          "' please confirm its name."
        }
        show={showDeleteSubscriptionInput}
        setShow={setShowDeleteSubscriptionInput}
        validateInput={(input: string) =>
          validateDeleteName(selectedSubscription, input)
        }
        onConfirm={(name: string) => removeSubscription(name)}
      />
      <div className="tableAction">
        <TooltipOnHover text="Create new Subscription">
          <Button
            variant="add-item"
            onClick={() => setShowSubscriptionCreateInput(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </TooltipOnHover>
      </div>
      <SubscriptionsTable subscriptions={subscriptions} deleteSubscription={askToDeleteSubscription} />
    </>
  );
};

export default SubscriptionsEditor;
