import { useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { ValidationResponse } from "../../api/resource";
import { SubscriptionType } from '../../api/subscriptions';
import useTimelines from '../../hooks/useTimelines';
import { Timeline } from "../../api/timelines";
import './CreateSubscriptionInput.css';

export type CreateSubscriptionInputProps = {
    show: boolean;
    timeline?: string;
    setShow: (show: boolean) => void;
    onClose?: () => void;
    onConfirm: (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>, startTime: Date | undefined) => void;
    validateInput?: (timelineName: string, subscriptionName: string, type: SubscriptionType, properties: Map<string, string>) => ValidationResponse | Promise<ValidationResponse>;
}

const NoErrors = { valid: true, errorMessage: undefined, fieldErrorMessages: {} };

const CreateSubscriptionInput = (props: CreateSubscriptionInputProps) => {

    let {timelines} = useTimelines();
    
    const [availableTimelines, setAvailableTimelines] = useState<string[]>([]);
    const [timelineName, setTimelineName] = useState("");
    const [subscriptionName, setSubscriptionName] = useState("");
    const [subscriptionType, setSubscriptionType] = useState("HTTP_PUSH_SUBSCRIPTION");
    const [startTime, setStartTime] = useState("NOW");
    const [pushUrl, setPushUrl] = useState("");
    const [error, setError] = useState<ValidationResponse>(NoErrors);
    const [inputTouched, setInputTouched] = useState<boolean>(false);

    useEffect(() => {
        validateInput();
    }, [timelineName, subscriptionName, subscriptionType, pushUrl, startTime, props.validateInput]);


    useEffect(() => {
        if (!timelines) {
            return;
        }
        if (props.timeline) {
            setAvailableTimelines([props.timeline]);
            setTimelineName(props.timeline);
            return;
        }   
             
        const allTimelineNames = timelines.map(timeline => timeline.name);
        setAvailableTimelines(allTimelineNames);
        setTimelineName(allTimelineNames[0]);
    }, [timelines, props.timeline]);
   

    const getSubscriptionType = () => {
        return subscriptionType === "HTTP_PUSH_SUBSCRIPTION" ? SubscriptionType.HTTP_PUSH : SubscriptionType.HTTP_FETCH;
    }

    const getSubscriptionProperties = () : Map<string, string> => {
        if (getSubscriptionType() === SubscriptionType.HTTP_PUSH) {
            return new Map([
                ['url', pushUrl ? pushUrl : ""],
            ]);
        } else {
            return new Map<string, string>([]);
        }
    }

    const validateInput = async (): Promise<boolean> => {
        if (props.validateInput) {
            const type = getSubscriptionType();
            const properties = getSubscriptionProperties();
            const validationResponse = await props.validateInput(timelineName, subscriptionName, type, properties);
            if (validationResponse) {
                setError(validationResponse);
                return validationResponse.valid;
            }
        }
        if (error.valid) {
            setError(NoErrors);
        }
        return true;
    }

    const close = () => {
        setError(NoErrors);
        setSubscriptionName("");
        setInputTouched(false);
        if (props.onClose) {
            props.onClose();
        }
        props.setShow(false);
    };

    const confirm = async () => {
        if (!(await validateInput())) {
            return;
        }

        setError(NoErrors);
        const type = getSubscriptionType();
        const properties = getSubscriptionProperties();
        props.onConfirm(
            timelineName, 
            subscriptionName,
            type,
            properties,
            startTime === "NOW" ? new Date() : undefined
        );
        setSubscriptionName("");
        setInputTouched(false);
        props.setShow(false);
    }

    if (!availableTimelines) {
        return (
            <Modal show={props.show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>No Timelines to subscribe to</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="noTimeline">
                        <Form.Label>You'll need to create a Timeline before your can subscribe.</Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="cancel" onClick={close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        ) 
    }

   return (
        <>
            <Modal show={props.show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>Create new Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="subscriptionInput">
                        <Form.Label>Please provide a name for the new Subscription:</Form.Label>
                        <Form.Control
                            className="input"
                            type="text"
                            key="name"
                            placeholder=""
                            value={subscriptionName}
                            isInvalid={inputTouched && error.fieldErrorMessages?.name !== undefined}
                            onChange={e => {
                                setSubscriptionName(e.target.value);
                                setInputTouched(true);
                            }} />
                        {error.fieldErrorMessages?.name !== undefined ?
                            (<div className="validation-error">
                                {inputTouched && error.fieldErrorMessages.name}
                            </div>) : null}
                    </Form.Group>
                    <Form.Group controlId="timelineInput">
                        <Form.Label>Select the Timeline to subscribe to:</Form.Label>
                        <Form.Select
                            className="input"
                            key="timelineName"
                            onChange={e => {
                                setTimelineName(e.target.value);
                                setInputTouched(true);
                            }}
                            value={timelineName}
                        >
                            {availableTimelines.map(timeline => (
                                <option value={timeline} key={timeline}>{timeline}</option>)
                            )}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group controlId="typeInput">
                        <Form.Label>Select the type of Subscription:</Form.Label>
                        <Form.Select
                            className="input"
                            key="type"
                            onChange={e => {
                                setSubscriptionType(e.target.value);
                                setInputTouched(true);
                            }}
                            value={subscriptionType}
                        >
                            <option value="HTTP_PUSH_SUBSCRIPTION" key="http-push">HTTP Push Subscription</option>
                            <option value="HTTP_FETCH_SUBSCRIPTION" key="http-fetch">HTTP Fetch Subscription</option>
                        </Form.Select>
                    </Form.Group>
                    {subscriptionType === "HTTP_PUSH_SUBSCRIPTION" ? (
                        <Form.Group controlId="pushUrlInput">
                            <Form.Label>Please provide a URL to push events to:</Form.Label>
                            <Form.Control
                                className="input"
                                key="pushUrl"
                                type="text"
                                placeholder=""
                                value={pushUrl}
                                isInvalid={inputTouched && error.fieldErrorMessages?.url !== undefined}
                                onChange={e => {
                                    setPushUrl(e.target.value);
                                    setInputTouched(true);
                                }} />
                            {error.fieldErrorMessages?.url !== undefined ?
                                (<div className="validation-error">
                                    {inputTouched && error.fieldErrorMessages.url}
                                </div>) : null}
                        </Form.Group>) : (<div />)
                    }
                    <Form.Group controlId="typeInput">
                        <Form.Label>Select which events to include in the Subscription:</Form.Label>
                        <Form.Select
                            className="input"
                            key="startTime"
                            onChange={e => {
                                setStartTime(e.target.value);
                                setInputTouched(true);
                            }}
                            value={startTime}
                        >
                            <option value="NOW" key="now">New events (Created starting now)</option>
                            <option value="BEGIN" key="begin">All events (Including events created in the past)</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="cancel" onClick={close}>
                        Close
                    </Button>
                    <Button variant="confirm" disabled={!error.valid} onClick={confirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CreateSubscriptionInput;