import { useEffect, useState } from 'react';
import { accessTokenEventString, getAccessToken } from '../api/token';

const useAccessToken = () => {
  const [token, setToken] = useState<string | undefined>(getAccessToken());

  useEffect(() => {
    const listener = () => {
      setToken(getAccessToken());
    }

    window.addEventListener(accessTokenEventString, listener);
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener(accessTokenEventString, listener);
      window.removeEventListener('storage', listener);
    }
  }, []);

  return token;
}

export default useAccessToken;