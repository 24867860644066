import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, Button, Container, OverlayTrigger, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useCurrentClient from "../../hooks/useCurrentClient";
import useTimelineAccessRights from "../../hooks/useTimelineAccessRights";
import LoadingIndicator from "../shared/LoadingIndicator";
import { TooltipOnHover } from "../shared/TooltipOnHover";
import SubscriptionsEditor from "../subscriptions/SubscriptionsEditor";
import LogContainer from "./LogContainer";
import PublishEventForm from "./PublishEventForm";
import ShareTimelineInput from "./ShareTimelineInput";

const TimelineScreen = () => {
  let params = useParams();
  const [currentClient] = useCurrentClient();
  let { timelineAccessRights, fetchTimelineAccessRights } = useTimelineAccessRights(params.timelineName);
  const [showShareTimelineInput, setShowShareTimelineInput] = useState(false);

  if (!params.timelineName) {
    return <h1>Invalid timeline name</h1>;
  }

  if (currentClient === undefined || timelineAccessRights === undefined) {
    return <LoadingIndicator />;
  }

  const askUserForAdditionalShareInformation = () => {
    setShowShareTimelineInput(true);
  };

  const onClose = () => {
    fetchTimelineAccessRights(params.timelineName);
  }

  return (
    <Container>
       <ShareTimelineInput
        timelineName={params.timelineName}
        show={showShareTimelineInput}
        setShow={setShowShareTimelineInput}
        onClose={onClose}
      />
      <div className="tableHeader">
        <h1>Timeline {params.timelineName}</h1>
        <p>Here, you can manage and inspect the timeline.</p>
      </div>
      <div className="panel">
        <h2>Shares</h2>
        <div className="tableAction">
          <TooltipOnHover text="Share timeline">
              <Button variant="add-item" onClick={() => askUserForAdditionalShareInformation()}>
                  <FontAwesomeIcon icon={faShare} />
              </Button>
        </TooltipOnHover>
      </div>
        <Table className="listingTable" hover size="sm">
          <thead>
            <tr>
              <th>Client</th>
              <th>Access Rights</th>
            </tr>
          </thead>
          <tbody>
            {timelineAccessRights
              ? timelineAccessRights.map((accessRight, idx) => (
                  <tr key={idx}>
                    <td className="text">{accessRight.clientId}</td>
                    <td className="text">
                      {accessRight.accessRights.map((right, idx) => (
                        <Badge key={idx} bg="info">
                          {right}
                        </Badge>
                      ))}
                    </td>
                  </tr>
                ))
              : undefined}
          </tbody>
        </Table>
      </div>
      <div className="panel">
        <h2>Subscriptions</h2>
        <SubscriptionsEditor timeline={params.timelineName}/>
      </div>
      <div className="panel">
        <h2>Event Log</h2>
        <LogContainer timelineName={params.timelineName} />
      </div>
      <div className="panel">
        <h2>Publish Event</h2>
        <PublishEventForm timelineName={params.timelineName} />
      </div>
    </Container>
  );
};

export default TimelineScreen;
