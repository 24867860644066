import {create, list, remove, validate, ValidationResponse} from './resource';

export type Timeline = {
    name: string,
    creationTime: Date,
    creatorId: string,
    eventCount: number,
    subscriptionCount: number
}

export enum TimelineAccessRight {
    READ,
    WRITE,
    MANAGE,
    DELETE
}

export interface GetTimelinesResponseBody {
    timelines: Timeline[]
}

export interface TimelineAccess {
    timelineName: string,
    clientId: string,
    accessRights: TimelineAccessRight[]
}

export interface GetTimelineAccessResponseBody {
    timelineAccess: TimelineAccess[]
}

export const getTimelines = async () => {
    const body: GetTimelinesResponseBody = await list('timelines');
    return body.timelines;
}

export const createTimeline = async (timelineName: string) => {
    await create('timelines', { name: timelineName});
}

export const validateTimeline = async (timelineName: string): Promise<ValidationResponse> => {
    try {
        return validate('timelines', {name: timelineName})
    } catch {
        return Promise.resolve({
            valid: true,
            errorMessage: undefined,
            fieldErrorMessages: undefined
        })
    }
}

export const deleteTimeline = async (timelineName: string) => {
    return remove('timelines', timelineName);
}

export const grantAccess = async (timelineName: string, clientId: string, accessRights: TimelineAccessRight[]) => {
    const body : any = {};
    body[clientId] = accessRights;
    return create(`timelines/${timelineName}/access`, {accessRights: body});
}

export const getTimelineAccessRights = async (timelineName: string) => {
    if (timelineName === "") {
        return [];
    }

    const body: GetTimelineAccessResponseBody = await list(`timelines/${timelineName}/access`);
    return body.timelineAccess;
}
