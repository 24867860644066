import { Container } from "react-bootstrap";
import SubscriptionsEditor from "./SubscriptionsEditor";
import "../shared/Tables.css";

const SubscriptionsList = () => {
  return (
    <Container>
      <div className="tableHeader">
        <h1>Subscriptions</h1>
        <p>Here, you can manage Subscriptions of Clients to Timelines.</p>
      </div>
      <SubscriptionsEditor />
    </Container>
  );
};

export default SubscriptionsList;
