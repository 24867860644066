import './WelcomeScreen.css'
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { doLogin } from '../../api/login'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faUndo, faBomb, faExclamationTriangle, faArrowCircleLeft, faInbox, faMagic, faEye, faMousePointer, faHeartBroken, faBolt } from '@fortawesome/free-solid-svg-icons'

const WelcomeScreen = () => {
    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [failed, setFailed] = useState(false);

    const sendMagicLink = () => {
        doLogin(email).then(() => {
            setSubmitted(true);
        }).catch(() => {
            setFailed(true);
        });
    };

    const tryAgain = () => {
        setFailed(false);
        setSubmitted(false);
    };

    return failed === true ?
    (
        <section>
            <div className="App-container">
                <h1>Oops, something went wrong.</h1>
            </div>
            <div className="message">
                <p>We're really sorry, but this did not work the way it should.</p>
            </div>
            <div className="big-icons"><FontAwesomeIcon icon={faExclamationTriangle} />&nbsp;<FontAwesomeIcon icon={faBomb} />&nbsp;<FontAwesomeIcon icon={faHeartBroken} /></div>
            <div className="message">
                <p>Please by kind enough to give it another try.</p>
            </div>
            <Form>
                <Button variant="action" onClick={e => tryAgain()}>
                    <FontAwesomeIcon icon={faUndo} />&nbsp;Try again
                </Button>
            </Form>
        </section>
    )
    : (submitted === true ?
        (
            <section>
                <div className="App-container">
                    <h1>You're almost there!</h1>
                </div>
                <div className="message">
                    <p>
                        We've sent an email to {email} that contains a magic link.<br/>
                        Please check your inbox and click the link.<br/>
                    </p>
                </div>
                <div className="big-icons"><FontAwesomeIcon icon={faEye} />&nbsp;<FontAwesomeIcon icon={faInbox} />&nbsp;<FontAwesomeIcon icon={faMousePointer} />&nbsp;<FontAwesomeIcon icon={faMagic} /></div>
                <div className="message">
                    <p>
                        If you can't find the email, make sure the email address you entered is correct<br/>
                        ...and check your Spam folder - just to be sure.
                    </p>
                </div>
                <Form>
                    <Button variant="action" onClick={e => tryAgain()}>
                        <FontAwesomeIcon icon={faArrowCircleLeft} />&nbsp;Go back to login form
                    </Button>
                    <Button variant="action" href="https://mail.google.com">
                        <FontAwesomeIcon icon={faInbox} />&nbsp;Go to my GMail inbox
                    </Button>
                </Form>
            </section>
        )
        :
        (
            <section>
                <div className="App-container">
                    <h1>Timelines</h1>
                    <p><FontAwesomeIcon icon={faBolt} />&nbsp;Event-Driven Architecture - As easy as it gets&nbsp;<FontAwesomeIcon icon={faBolt} /></p>
                    <p className="space40" />
                    <p className="description">What the heck is <i>Timelines</i>?</p>
                    <p className="description"><i>Timelines</i> is a managed service that connects applications using events. Events can be contributed by one service and other services can subscribe to them. It's a bit like Kafka and RabbitMQ, but much easier to use and way easier to understand.</p>
                    <p className="space40" />
                </div>
                <Form onSubmit={e => {
                    e.preventDefault();
                    sendMagicLink();
                }}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control 
                            className="email-input" 
                            type="email" 
                            placeholder="firstname.lastname@devboost.com" 
                            value={email}
                            onChange={e => setEmail(e.target.value)}/>
                        <Form.Text className="text-muted">
                            Enter your email address to receive a magic login link.
                        </Form.Text>
                    </Form.Group>
                    <Button type="submit" variant="action">
                        <FontAwesomeIcon icon={faPaperPlane} />&nbsp;Send the magic link
                    </Button>
                </Form>
            </section>
        )
    );
}

export default WelcomeScreen;
