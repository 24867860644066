import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <div>
            <div className="footer-phantom" />
            <div className="footer-content" >
                Made with <FontAwesomeIcon icon={faHeart} color='#DD3C57' />&nbsp;by <a href="https://devboost.com" className='footer-link'>DevBoost</a>
            </div>
        </div>
    )
}

export default Footer;
