import { baseUrl } from "./constants";

export type LoginResponse = {
    success: boolean
}

export const doLogin = async (email : string) => {
    return fetch(baseUrl + 'login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email
        })
    }).then(res => {
        if (res.status === 200) {
            return;
        }
        throw Error("Signup request failed");
    });
}

export type ExchangeResponse = {
    token: string
}

export const getAccessToken = async (magicLinkToken : string): Promise<ExchangeResponse> => {
    return fetch(baseUrl + 'login/exchange', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            magicLinkToken: magicLinkToken
        })
    }).then(res => {
        if (res.status === 200) {
            return res.json();
        }
        throw Error("Magic link exchange request failed");
    });
}
