import './MenuBar.css'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { removeAccessToken } from '../../api/token';
import { NavLink } from 'react-router-dom';
import useAccessToken from '../../hooks/useAccessToken';
import useCurrentClient from '../../hooks/useCurrentClient';

const MenuBar = () => {
    const token = useAccessToken();
    const [currentClient] = useCurrentClient();

    const logout = () => {
        removeAccessToken();
    }

    const protectedNavigation = token === undefined ? undefined : (
        <>
            <NavLink className='nav-link' to="/timelines">Timelines</NavLink>
            <NavLink className='nav-link' to="/subscriptions">Subscriptions</NavLink>
            <NavLink className='nav-link' to="/clients">Clients</NavLink>
        </>
    );

    const logoutButton = token === undefined ? undefined : (
        <>
            <Nav.Link onClick={logout}>Logout{currentClient?.clientId ? (" (" + currentClient.clientId + ")") : ""}</Nav.Link>
        </>
    );

    return (
        <Navbar bg="light" fixed="top" className="menuBar">
            <Container>
                <NavLink className='navbar-brand' to="/">Timelines</NavLink>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {protectedNavigation}
                        <NavLink className='nav-link' to="/docs">Documentation</NavLink>
                    </Nav>
                    <Nav>
                        {logoutButton}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MenuBar;
