const accessTokenKey = 'accessToken';

export const accessTokenEventString = 'accesstoken';

export const notifyTokenChange = () => {
    window.dispatchEvent(new Event(accessTokenEventString))
}

export const getAccessToken = () => {
    let accessToken = localStorage.getItem(accessTokenKey);
    if (accessToken === null) {
        return undefined;
    }
    return accessToken;
}

export const removeAccessToken = () => {
    localStorage.removeItem(accessTokenKey);
    notifyTokenChange();
}

export const saveAccessToken = (newAccessToken: string) => {
    localStorage.setItem(accessTokenKey, newAccessToken);
    notifyTokenChange();
}
