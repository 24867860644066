import { current, create, list, remove, validate, ValidationResponse } from './resource';

export type Client = {
    clientId: string,
    creationTime: Date,
    accessToken: string,
    human: boolean,
    admin: boolean,
    creatorId: string
}

export interface GetClientResponseBody {
    client: Client
}

export const getCurrentClient = async () => {
    const body: GetClientResponseBody = await current('clients');
    return body.client;
}

export interface GetClientsResponseBody {
    clients: Client[]
}

export const getClients = async () => {
    const body: GetClientsResponseBody = await list('clients');
    return body.clients;
}

export interface ClientRequestBody {
    clientId: string
    human: boolean
    admin: boolean
}

export const createClient = async (client: ClientRequestBody) => {
    await create('clients', client);
}

export const validateClient = async (client: ClientRequestBody): Promise<ValidationResponse> => {
    return validate('clients', client)
}

export const deleteClient = async (clientName: string) => {
    return remove('clients', clientName);
}