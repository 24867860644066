import '../shared/Tables.css'
import { Button } from 'react-bootstrap';
import { useParams, useNavigate } from "react-router-dom";
import { getAccessToken } from '../../api/login';
import { saveAccessToken } from '../../api/token';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUmbrellaBeach, faUndo } from '@fortawesome/free-solid-svg-icons'

enum ExchangeStatus {
    INIT,
    FAILED
}

const MagicLinkScreen = () => {

    const [exchangeStatus, setExchangeStatus] = useState(ExchangeStatus.INIT);

    let params = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        if (!params.token) {
            return;
        }
        getAccessToken(params.token).then(response => {
            saveAccessToken(response.token);
            navigate("/");
        }).catch(error => {
            setExchangeStatus(ExchangeStatus.FAILED);
        })
    });

    if (params.token === undefined) {
        return <div>No token provided</div>;
    }

    switch (exchangeStatus) {
        case ExchangeStatus.INIT:
            return (
                <section>
                    <div className="App-container">
                        <FontAwesomeIcon icon={faUmbrellaBeach} />
                        <h1>Validating your magic link. This should not take long...</h1>
                    </div>
                </section>
            )
        case ExchangeStatus.FAILED:
            return (
                <section>
                    <div className="App-container">
                        <h1>Sorry, that did not work. It seems somethings wrong with your magic link.</h1>
                    </div>
                    <Button variant="primary" href="/">
                        <FontAwesomeIcon icon={faUndo} />&nbsp;Go back and get a new link
                    </Button>
                </section>
            );
    }
}

export default MagicLinkScreen;
