import { useEffect, useState } from "react";
import { getTimelineAccessRights, TimelineAccess } from "../api/timelines";

const useTimelineAccessRights = (timeline: string | undefined) => {
   
    let [timelineAccessRights, setTimelineAccessRights] = useState<TimelineAccess[] | undefined>(undefined);
   
    const fetchTimelineAccessRights = async (timelineName: string | undefined) => {
        if (!timelineName) {
            return;
        }
        let newTimelineAccessRights = await getTimelineAccessRights(timelineName);
        setTimelineAccessRights(newTimelineAccessRights);
    };

    useEffect(() => {
        fetchTimelineAccessRights(timeline);
    }, [timeline]);

    return {timelineAccessRights, fetchTimelineAccessRights};
}
export default useTimelineAccessRights;
