import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import { publishEvent }from '../../api/events';
import { toast } from 'react-toastify';

export type PublishEventFormProps = {
    timelineName: string;
}

export const PublishEventForm = (props: PublishEventFormProps) => {

    const [payload, setPayload] = useState("{}");
    const [validPayload, setValidPayload] = useState(true);
   
    const validatePayload = (payload: string) => {
        try {
            JSON.parse(payload);
            setValidPayload(true);
        } catch (e) {
            setValidPayload(false);
        }
    }

    const setAndValidatePayload = (newPayload: string) => {
        setPayload(newPayload);
        validatePayload(newPayload);
    }

    const doPublishEvent = async () => {
        const blob = new Blob([payload], {
            // FIXME Use JSON here?
            type: 'text/plain'
        });
        await publishEvent(props.timelineName, blob);
        toast.success("Event posted");
    }

    return (
        <Form>
            <Form.Group controlId="try">
                <Form.Label>Event Payload</Form.Label>
                <Form.Control 
                    as="textarea"
                    type="text"
                    rows={6}
                    className="input" 
                    placeholder='{"orderId": "123"}'
                    value={payload}
                    isValid={validPayload}
                    isInvalid={!validPayload}
                    onChange={e => {
                        setAndValidatePayload(e.target.value);
                    }}
                />
            </Form.Group>
            <Button variant="confirm" disabled={!validPayload} onClick={doPublishEvent}>Publish Event</Button>
        </Form>
    );
}

export default PublishEventForm;
