import { useEffect, useState } from "react";
import { ExampleRequests, getExampleRequests } from "../api/examples";

const useExampleRequests = (baseUrl: string): ExampleRequests | undefined => {
    let [examples, setExamples] = useState<ExampleRequests | undefined>(undefined);
    useEffect(() => {
        setExamples(undefined);
        const fetchExamples = async () => {
            let newExamples = await getExampleRequests(baseUrl);
            setExamples(newExamples);
        };
        fetchExamples();
    }, []);
    return examples;
}
export default useExampleRequests;
