import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { Badge, Button, Form, Table } from "react-bootstrap";
import {
  Column,
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { Subscription, SubscriptionType } from "../../api/subscriptions";
import FormattedDate from "../shared/FormattedDate";
import { TooltipOnHover } from "../shared/TooltipOnHover";

export type SubscriptionsTableProps = {
  subscriptions: Subscription[];
  deleteSubscription: (subscriptionName: string) => void;
};

type ColumnWithClassName<D extends object = {}> = Column<D> & {
  className?: string;
};

type GlobalFilterType = {
  globalFilter: any;
  setGlobalFilter: (filter: any) => void;
};

const GlobalFilter = ({ globalFilter, setGlobalFilter }: GlobalFilterType) => {
  const [value, setValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((filterValue) => {
    setGlobalFilter(filterValue || undefined);
  }, 200);

  return (
    <Form.Control
      className="input"
      type="text"
      placeholder="Enter filter term"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
};

const SubscriptionsTable = (props: SubscriptionsTableProps) => {
  const getColumnStyle = (column: ColumnWithClassName<Subscription>) => {
    return { className: column.className };
  };

  const columns: ColumnWithClassName<Subscription>[] = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "text",
      },
      {
        Header: "Timeline",
        accessor: "timelineName",
        className: "text",
      },
      {
        Header: "Type",
        accessor: "type",
        className: "text",
        Cell: (cell) => {
          if (cell.value === SubscriptionType[SubscriptionType.HTTP_FETCH]) {
            return <Badge bg="secondary">HTTP Fetch</Badge>;
          }
          return <Badge bg="primary">HTTP Push</Badge>;
        },
      },
      {
        Header: "Properties",
        accessor: "properties",
        className: "text",
        Cell: (cell) => {
          return Object.keys(cell.value ? cell.value : {})?.map(
            (key, _index) => (
              <>
                <Badge bg="info">{key.toUpperCase()}</Badge>
                {cell.value[key]}
              </>
            )
          );
        },
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        className: "text",
        Cell: (cell) => <FormattedDate date={cell.value} />,
      },
      {
        Header: "Creation Time",
        accessor: "creationTime",
        className: "text",
        Cell: (cell) => <FormattedDate date={cell.value} />,
      },
      {
        Header: "Creator",
        accessor: "creatorId",
        className: "text",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable<Subscription>(
    {
      columns,
      data: props.subscriptions,
    },
    useGlobalFilter
  );

  if (props.subscriptions.length === 0) {
    return <div>No Subscriptions found. Feel free to add some.</div>;
  }
  return (
    <>
      <Table className="listingTable" hover size="sm" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>

        {
          <tbody {...getTableBodyProps()}>
            <tr className="tableFilter">
              <td colSpan={10}>
                <GlobalFilter 
                  globalFilter={state.globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </td>
            </tr>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps([getColumnStyle(cell.column)])}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td className="rowButtons">
                    {
                      // FIXME <Button variant="action" onClick={editSubscription}><FontAwesomeIcon icon={faEdit} /></Button>
                    }
                    <TooltipOnHover text="Delete Subscription">
                      <Button
                        variant="action"
                        onClick={() =>
                          props.deleteSubscription(row.original.name)
                        }
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </Button>
                    </TooltipOnHover>
                  </td>
                </tr>
              );
            })}
          </tbody>
        }
      </Table>
    </>
  );
};

export default SubscriptionsTable;
