import './Footer.css';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingIndicator = () => {

    const [show, setShow] = useState(false);

    useEffect(
        () => {
            let timer1 = setTimeout(() => setShow(true), 1000);

            // this will clear Timeout
            // when component unmount like in willComponentUnmount
            // and show will not change to true
            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );

    return show ? (
        <div>
            <FontAwesomeIcon icon={faSpinner} spin />
        </div>
    ) : null;
}

export default LoadingIndicator;
