import './Modals.css';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";

export type ModalTextInputProps = {
    title: string;
    message: string;
    show: boolean;
    setShow: (show: boolean) => void;
    onClose?: () => void;
    onConfirm: (input: string) => void;
    validateInput?: (input: string) => string | undefined | Promise<string | undefined>;
}

const ModalTextInput = (props: ModalTextInputProps) => {

    const [input, setInput] = useState("");
    const [error, setError] = useState("");
    const [inputTouched, setInputTouched] = useState<boolean>(false);

    useEffect(() => {
        validateInput();
    }, [input, props.show]);

    const validateInput = async (): Promise<boolean> => {
        if (props.validateInput) {
            let validationError: string | undefined
            validationError = await props.validateInput(input);
            if (validationError) {
                setError(validationError);
                return false;
            }
        }
        if (error) {
            setError("");
        }
        return true;
    }

    const close = () => {
        setError("");
        setInput("");
        setInputTouched(false);
        if (props.onClose) {
            props.onClose();
        }
        props.setShow(false);
    };

    const confirm = async () => {
        if (!(await validateInput())) {
            return;
        }
        setError("");
        props.onConfirm(input);
        setInput("");
        setInputTouched(false);
        props.setShow(false);
    }

    return (
        <>
            <Modal show={props.show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.message}</p>
                    <Form.Group controlId="formInput">
                        <Form.Control
                            className="input"
                            type="text"
                            placeholder=""
                            value={input}
                            isInvalid={inputTouched && !!error}
                            onChange={e => {
                                setInput(e.target.value);
                                setInputTouched(true);
                            }} />

                        <div className="validation-error">
                            {inputTouched && error}
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="cancel" onClick={close}>
                        Close
                    </Button>
                    <Button variant="confirm" disabled={!!error} onClick={confirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalTextInput;